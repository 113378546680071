<template>
    <div class="flex flex-col">
        <autralis-input-group-comp label="Current password"
                                   type="password"
                                   placeholder="Current password"
                                   required
                                   @on-change="password = $event"
                                   :error="!checking && passwordCorrect === false ? 'Password not correct.' : ''"
                                   :success="!checking && passwordCorrect === true ? 'Password correct.' : ''"
                                   @on-blur="checkCurrentPassword"
                                   :value="password"/>
        <div v-if="checking" class="flex items-center mt-1">
            <span class="fas fa-spinner-third fa-spin mr-2"></span>
            <translate>Checking</translate> ...
        </div>
    </div>
</template>

<script>
import axios from "axios";
import InputGroup from "@/components/input-group";

export default {
    name: "CurrentPassword",
    props: {
        saved: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            type: 'password',
            checking: false,
            password: '',
            error: null,
            passwordCorrect: null,
            lastCheckedPassword: ''
        }
    },

    watch: {
        saved: function (newVal) {
            if (newVal) this.resetForm()
        }
    },
    methods: {
        resetForm() {
            // this.type = 'password'
            this.checking = false
            this.password = ''
            this.error = null
            this.passwordCorrect = null
            this.lastCheckedPassword = ''
        },
        async checkCurrentPassword() {
            if (this.password !== '' && this.lastCheckedPassword !== this.password) {
                try {
                    this.checking = true;
                    this.error = null;
                    const response = await axios.post('/manager/expert3/xp3/check-current-password/', {password: this.password}, {withCredentials: true})

                    this.passwordCorrect = response.data.result === 'Ok';
                    this.checking = false;
                    this.$emit('on-change', response.data.result === 'Ok' ? this.password : '')

                } catch (err) {
                    this.error = 'The current password could not be checked.'
                    this.checking = false;
                    this.passwordCorrect = null
                }
                this.lastCheckedPassword = this.password;
            }
        },

        togglePassword() {
            this.type = this.type === 'password' ? 'text' : 'password';
        }
    },

    components: {
        'autralis-input-group-comp': InputGroup
    }
}
</script>

<style scoped>

</style>